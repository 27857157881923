<template>
<structure :cate="1"></structure>
</template>

<script>
import Structure from '@/views/layout/Structure'
export default {
    components: { Structure },

   
};
</script>

<style lang="scss" scoped>
</style>